import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useApi, Recipe } from '../services/api';
import RecipeList from '../components/RecipeList';
import { Helmet } from 'react-helmet-async';

const SharedRecipe: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState<Recipe[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const api = useApi();

  useEffect(() => {
    let mounted = true;  // Add mounted flag

    const fetchRecipe = async () => {
      if (!uuid) {
        setError('Recipe ID is missing');
        setLoading(false);
        return;
      }

      try {
        const { recipes: fetchedRecipes } = await api.getRecipeById(uuid);
        if (mounted) {  // Only update state if component is mounted
          setRecipes(fetchedRecipes);
        }
      } catch (err) {
        console.error('Error fetching shared recipe:', err);
        if (mounted) {  // Only update state if component is mounted
          setError('Error loading recipe. Please try again.');
        }
      } finally {
        if (mounted) {  // Only update state if component is mounted
          setLoading(false);
        }
      }
    };

    fetchRecipe();

    // Cleanup function to prevent state updates after unmount
    return () => {
      mounted = false;
    };
  }, []); // Remove api from dependencies, add uuid if needed

  console.log('SharedRecipe render', { loading, error, recipesLength: recipes?.length });  // Add logging to track renders

  if (loading) {
    return (
      <div className="recipe-suggestions">
        <div className="loading">Loading shared recipe...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="recipe-suggestions">
        <div className="error">{error}</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        {recipes && recipes[0] && (
          <>
            <title>{`${recipes[0].meal} Recipe - Ratatouille NYC`}</title>
            <meta name="description" content={`Learn how to make ${recipes[0].meal} using ${recipes[0].ingredients.join(', ')}. ${recipes[0].is_vegetarian ? 'Vegetarian recipe.' : ''} ${recipes[0].is_vegan ? 'Vegan recipe.' : ''}`} />
            <meta name="keywords" content={`${recipes[0].meal}, recipe, cooking, ${recipes[0].cuisine} cuisine, ${recipes[0].ingredients.join(', ')}`} />
            <link rel="canonical" href={`https://ratatouille.nyc/recipes/${uuid}`} />
            
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="article" />
            <meta property="og:url" content={`https://ratatouille.nyc/recipes/${uuid}`} />
            <meta property="og:title" content={`${recipes[0].meal} Recipe - Ratatouille NYC`} />
            <meta property="og:description" content={`Cook ${recipes[0].meal} in ${recipes[0].cook_time} minutes. ${recipes[0].calories} calories per serving.`} />
            
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={`https://ratatouille.nyc/recipes/${uuid}`} />
            <meta name="twitter:title" content={`${recipes[0].meal} Recipe - Ratatouille NYC`} />
            <meta name="twitter:description" content={`Cook ${recipes[0].meal} in ${recipes[0].cook_time} minutes. ${recipes[0].calories} calories per serving.`} />
          </>
        )}
      </Helmet>
      <div className="recipe-suggestions">
        {recipes ? (
          <RecipeList recipes={recipes} />
        ) : (
          <div>No recipe found.</div>
        )}
        <button 
          type="button" 
          className="button get-recipes-button" 
          onClick={() => navigate('/')}
        >
          Generate Your Own
        </button>
      </div>
    </>
  );
};

export default SharedRecipe; 