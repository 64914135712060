import React, { useState } from 'react';
import '../styles/About.css';
import { Helmet } from 'react-helmet-async';

const About: React.FC = () => {
    const [expandedCard, setExpandedCard] = useState<number | null>(null);

    const toggleCard = (cardNumber: number) => {
        setExpandedCard(expandedCard === cardNumber ? null : cardNumber);
    };

    return (
        <>
            <Helmet>
                <title>About Ratatouille NYC - Our Mission to Reduce Food Waste</title>
                <meta name="description" content="Learn about Ratatouille NYC's mission to reduce food waste through smart recipe suggestions and sustainable cooking practices in New York City." />
                <meta name="keywords" content="food waste reduction, sustainable cooking, NYC food initiatives, kitchen sustainability, recipe app" />
                <link rel="canonical" href="https://ratatouille.nyc/about" />
                
                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ratatouille.nyc/about" />
                <meta property="og:title" content="About Ratatouille NYC - Fighting Food Waste" />
                <meta property="og:description" content="Our goal is to make reducing food waste easy and impactful. Learn about our mission and vision." />
                <meta property="og:image" content="https://ratatouille.nyc/tomato_stems.png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://ratatouille.nyc/about" />
                <meta name="twitter:title" content="About Ratatouille NYC - Fighting Food Waste" />
                <meta name="twitter:description" content="Our goal is to make reducing food waste easy and impactful. Learn about our mission and vision." />
                <meta name="twitter:image" content="https://ratatouille.nyc/tomato_stems.png" />
            </Helmet>
            <div className="about-container">
                <div className="about-header">
                    <h2  className="title">About Us</h2>
                </div>
                <p className="about-description">Find recipes with what's in your fridge. Cook what you have on hand. Waste less. Save more.</p>


                 <div className="about-tomatoes">
                    <img src="/tomato_stems.png" alt="Tomato" className="tomato-icon" />
                </div>


                <div className="cards-section">
                    {/* Card 0 */}
                    <p className="expanded-content">
                    Our goal is to make reducing food waste easy and impactful. By helping you find creative ways to use what's already in your fridge, we aim to minimize food waste, lower our environmental impact, and inspire sustainable habits. From transforming forgotten ingredients into delicious meals to making smarter choices in the kitchen, we believe small actions can lead to big change. Together, we can create a future where food is valued, not wasted, and where every meal makes a difference.                    </p>

                    {/* Card 1 */}
                    <p className="card-title">Food Waste is a Global Issue</p>
                    <p className="expanded-content">
                    Food waste is more than just a problem for your trash can—it's a global challenge that affects our environment, economy, and communities. Did you know that around 40% of food produced in the U.S. is wasted? That's over 119 billion pounds of food every year, enough to fill countless landfills and release harmful greenhouse gases. But the solution can start right in your kitchen. By using what's already in your fridge, you can make a meaningful difference. Whether it's the half-used vegetables sitting in the crisper or the leftover pasta from last night, we suggest recipes that turn potential waste into fresh, flavorful dishes. What starts as a simple choice to save a lonely carrot can ripple outward, inspiring others and creating waves of positive change. Together, our small steps in the kitchen today can transform how we value and preserve food tomorrow.                    </p>

                    {/* Card 2 */}
                    <p className="card-title">Less Bodega, More Kitchen</p>
                    <p className="expanded-content">
                    We get it—the deli man across the street is calling your name, and those delivery apps are always pinging you. But what if the secret to your next great meal is already hiding in your fridge? With a dash of creativity, you can transform yesterday's rice and those "I should probably use these soon" vegetables into something amazing. No need to brave the dinner rush or wait for another delivery person to hunt down your fifth-floor walkup. Our app helps you channel your inner chef and make the most of what you've got, turning ordinary leftovers into dishes you'll actually want to eat. Turn your tiny kitchen into your favorite new spot to grab a bite, save some cash (and the planet while you're at it), and maybe even impress your roommates enough that they'll finally do the dishes.
                    </p>

                    {/* Card 3 */}
                    <p className="card-title">Small Bites, Big Change</p>
                    <p className="expanded-content">
                        The small actions we take in our kitchens can ripple out to create larger change. Reducing food waste isn't just about saving groceries. Every meal you make from leftovers or unused ingredients helps reduce the demand for new food production, which in turn cuts down on the water, energy, and labor that go into farming, transporting, and storing food. Did you know that wasting food squanders nearly 20% of freshwater used in the U.S. and contributes to 8% of global greenhouse gas emissions? By being more mindful of what's in your fridge and making the most of your groceries, you're not only helping the environment but also setting an example for others. And it doesn't have to be difficult—something as simple as roasting leftover vegetables to make a soup or blending overripe fruit into a smoothie can turn would-be waste into something delicious.
                    </p>
                    <br/>
                    <p className="expanded-content">
                        Together, we can be part of the solution. Reducing food waste on an individual level might seem small, but it's a crucial step toward tackling a much bigger issue. In fact, if every household reduced food waste by just 25%, it would have a huge impact—saving enough food to feed millions of people while reducing the strain on our planet. Start by being mindful of what you buy, store, and cook. Our app can guide you in making smarter choices by showing how to use up ingredients you already have. Whether it's freezing leftovers, planning meals ahead, or repurposing food scraps, there are countless ways to cut down on waste and be part of a movement toward sustainability. Let's make food waste a thing of the past—one meal, one ingredient, one kitchen at a time.
                    </p>


                    <p className="card-title-small">We'd Love to Hear From You</p>
                    <p className="expanded-content">
                        We're always looking to hear from our users. If you have thoughts, ideas, or even just a quick comment, we'd love to hear it. Every bit of feedback helps us grow. Please let us know what you think <a href="https://docs.google.com/forms/d/e/1FAIpQLSfAUxW8E-J93jakl7F9t8Ni4iylDg54DOxA7B03GczpuWPzEg/viewform?usp=header" target="_blank" rel="noopener noreferrer" className="highlight-email">here</a>.
                    </p>
                </div>
            
                <p className="disclaimer">
                    Disclaimer: Ratatouille NYC is not affiliated in any way with the 2007 motion picture "Ratatouille" produced by Pixar Animation Studios and Walt Disney Pictures. Any similarities in name are purely coincidental, and our service is focused on reducing food waste through creative kitchen solutions.
                </p>


            </div>
        </>
    );
};

export default About;
