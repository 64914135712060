import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.css';
import { Helmet } from 'react-helmet-async';

const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Ratatouille NYC - Find Recipes With What's In Your Fridge</title>
        <meta name="description" content="Reduce food waste and discover delicious recipes with ingredients you already have. Turn your leftover ingredients into amazing meals with Ratatouille NYC." />
        <meta name="keywords" content="recipe finder, food waste reduction, leftover ingredients, cooking app, NYC food, kitchen management" />
        <link rel="canonical" href="https://ratatouille.nyc" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ratatouille.nyc" />
        <meta property="og:title" content="Ratatouille NYC - Smart Recipe Finder" />
        <meta property="og:description" content="Find recipes with what's in your fridge. Cook what you have on hand. Throw away less food. Make an impact. Save More" />
        <meta property="og:image" content="https://ratatouille.nyc/rat.svg" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://ratatouille.nyc" />
        <meta name="twitter:title" content="Ratatouille NYC - Smart Recipe Finder" />
        <meta name="twitter:description" content="Find recipes with what's in your fridge. Cook what you have on hand. Throw away less food." />
        <meta name="twitter:image" content="https://ratatouille.nyc/rat.svg" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Ratatouille NYC",
            "url": "https://ratatouille.nyc",
            "description": "Find recipes with what's in your fridge. Reduce food waste and discover delicious recipes.",
            "keywords": "recipe finder, sustainable food, recipe generator, food waste reduction, leftover ingredients, recipe ideas, cooking app, kitchen management"
          })}
        </script>
      </Helmet>
      <div className="home-container">
        <div className="home-header">
          <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo"/>
          <h1 className="title">Ratatouille NYC</h1>
        </div>
        <p className="description">
          Find recipes with what's in your fridge. Cook what you have on hand. Throw away less food. Make an impact. Save More
        </p>
        <Link to="/recipes" className="link-white">
          <button className="recipe-button">
            Let's Cook
          </button>
        </Link>
      </div>
    </>
  );
};

export default Home;