import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { animated, useTransition, useChain, useSpring, useSpringRef } from 'react-spring';
import '../styles/BurgerMenu.css';
import '../styles/MobileHeader.css';
import '../styles/menu_open.css';

interface MobileHeaderProps {
  isLoggedIn: boolean;
  iconColor?: string;
  filledbg?: boolean;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ isLoggedIn, iconColor = 'black', filledbg = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);

  const closeMenu = () => {
    setIsOpen(false);
    setActiveSubMenu(null);
  };

  const menuItems = [
    { name: 'HOME', link: '/' },
    { name: 'FIND RECIPES', link: '/recipes' },
    { name: 'ABOUT', link: '/about' },
    { name: 'FAQ', link: '/faq' },
    { name: 'KITCHEN TIPS', link: '/kitchen_tips' },
    { name: 'CONTACT', link: '/contact' },
    ...(isLoggedIn ? [{ name: 'ACCOUNT', link: '/account' }] : [
      { name: 'LOG IN', link: '/login' },
      { name: 'SIGN UP', link: '/signup' }
    ])
  ];

  // Create SpringRefs for useChain
  const linksRef = useSpringRef();

  // Setup animations for link items
  const linkTransitions = useTransition(
    isOpen ? menuItems : [],
    {
      key: (item: { name: string; link: string }) => item.name,
      ref: linksRef,
      trail: 400 / menuItems.length,
      from: { opacity: 0, transform: 'translateY(20px)' },
      enter: { opacity: 1, transform: 'translateY(0)' },
      leave: { opacity: 0, transform: 'translateY(20px)', delay: 200 }
    }
  );

  // On isOpen, start with link items animation
  useChain(isOpen ? [linksRef] : [linksRef], [0]);

  const { x } = useSpring({
    from: { x: 0 },
    to: { x: isOpen ? 1 : 0 },
    config: { duration: 75 },
  });

  const trans1 = x.to({
    range: [0, 1],
    output: [0, 45],
  }).to((x) => `rotate(${x}deg) translateY(-3px)`);

  const trans2 = x.to({
    range: [0, 1],
    output: [1, 0],
  });

  const trans3 = x.to({
    range: [0, 1],
    output: [0, -45],
  }).to((x) => `rotate(${x}deg) translateY(3px)`);

  return (
    <div className={`header-container ${filledbg ? 'filled' : ''}`}>
      <div className="burger-icon" onClick={() => setIsOpen(!isOpen)}>
        <animated.div style={{ transform: trans1, backgroundColor: iconColor }} />
        <animated.div style={{ opacity: trans2, backgroundColor: iconColor }} />
        <animated.div style={{ transform: trans3, backgroundColor: iconColor }} />
      </div>
      {filledbg && (
        <>
          <div style={{ flex: 1 }}></div>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="header-logo">
              Ratatouille NYC
            </div>
          </Link>
          <Link to="/">
            <img className="rat-logo" src="/rat.svg" alt="Rat Logo" />
          </Link>
          <div style={{ flex: 1 }}></div>
        </>
      )}
      <div className={`bm-menu ${isOpen ? 'open' : ''}`}>
        <animated.div className="bm-item-list">
          {linkTransitions((style, item) => (
            <animated.div key={item.name} style={style}>
              <Link to={item.link} onClick={closeMenu}>{item.name}</Link>
            </animated.div>
          ))}
        </animated.div>
      </div>
    </div>
  );
}

export default MobileHeader;