import axios from 'axios';
import { useSession } from '../context/SessionContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create a configured axios instance with a basic header that's harder to spot
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    // Use a custom header name that doesn't obviously indicate it's an auth token
    'X-Client-Id': btoa('51O8gkBXjK9vYRPMqwXZplBn3DaUQHvtGM8NiYF2pL').split('').reverse().join('')
  },
});

export interface Recipe {
  meal: string;
  ingredients: string[];
  cuisine: string;
  is_vegan: boolean;
  is_vegetarian: boolean;
  is_gluten_free: boolean;
  is_dairy_free: boolean;
  calories: number;
  cook_time: number;
  instructions: string;
}

export interface AuthResponse {
  // access_token?: string;
  // token_type?: string;
  user_id?: string;
  user_name?: string;
  user_email?: string;
  authentication_status?: boolean;
  preferred_language?: string;
}

export interface RecipeSuggestionsResponse {
  recipes: Recipe[];
  session_uuid: string;
  responseUserId: string;
  recipe_id: string;
}

// Create a custom hook that combines API calls with session management
export const useApi = () => {
  const { sessionId } = useSession();

  const getRecipeSuggestions = async ({
    ingredients,
    cuisine,
    is_vegan,
    is_vegetarian,
    is_gluten_free,
    is_dairy_free,
    user_id
  }: {
    ingredients: string[];
    cuisine?: string;
    is_vegan?: boolean;
    is_vegetarian?: boolean;
    is_gluten_free?: boolean;
    is_dairy_free?: boolean;
    user_id?: string;
  }): Promise<RecipeSuggestionsResponse> => {
    try {
      const response = await api.post<RecipeSuggestionsResponse>('/get_recipe_ideas', {
        ingredients,
        cuisine,
        is_vegan,
        is_vegetarian,
        is_gluten_free,
        is_dairy_free
      }, {
        headers: {
          'Session-UUID': sessionId,
          'user_id': user_id
        }
      });

      console.log("API response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching recipe suggestions:", error);
      throw error;
    }
  };

  const getSessionRecipes = async (sessionUUID: string): Promise<Recipe[]> => {
    try {
      const response = await api.get<Recipe[]>('/get_generated_recipes', {
        headers: {
          'Session-UUID': sessionUUID
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching session recipes:", error);
      throw error;
    }
  };

  const loginUser = async (email: string, password: string): Promise<AuthResponse> => {
    try {
      const response = await api.post<AuthResponse>('/authenticate_user', {
        user_email: email,
        password: password
      });
      return response.data;
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  };

  const signupUser = async (email: string, password: string, user_name: string, language: string, authentication_method: string): Promise<AuthResponse> => {
    try {
      const response = await api.post<AuthResponse>('/signup', {
        user_email: email,
        password: password,
        user_name: user_name,
        preferred_language: language,
        authentication_method: authentication_method
      });
      return response.data;
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
  };

  const getRecipeById = async (recipeId: string): Promise<RecipeSuggestionsResponse> => {
    try {
      const response = await api.get<RecipeSuggestionsResponse>(`/recipes/${recipeId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching recipe by ID:", error);
      throw error;
    }
  };

  return {
    getRecipeSuggestions,
    getSessionRecipes,
    loginUser,
    signupUser,
    getRecipeById,
  };
};

export default api;