import React, { useEffect, useState } from 'react';
import '../styles/About.css';
import api from '../services/api'; // Assuming you have an axios instance configured

const Faq: React.FC = () => {
    const [totalRecipes, setTotalRecipes] = useState<number | null>(null);

    useEffect(() => {
        const fetchTotalRecipes = async () => {
            try {
                const response = await api.get<number>('/get_total_num_recipes');
                setTotalRecipes(response.data);
            } catch (error) {
                console.error("Error fetching total number of recipes:", error);
            }
        };

        fetchTotalRecipes();
    }, []);

    return (
        <div className="about-container">
            <div className="about-header">
                <h2 className="title">FAQ's</h2>
            </div>
            <p className="about-description">We hope you can find the answers you're looking for, Chef. Please visit our contact page if we're missing something.</p>


            <div className="about-tomatoes">
                <img src="/tomato_stems.png" alt="Tomato" className="tomato-icon" />
            </div>

            <div className="cards-section">
                {/* FAQ Item 1 */}
                <p className="card-title-small">How much food have you saved?</p>
                <p className="expanded-content">
                    We have generated {totalRecipes !== null ? totalRecipes : '...'} recipes total so far. If we assume that users actually cooked 5% of those recipes, we can calculate that approximately {totalRecipes !== null ? (totalRecipes * 0.05 * 0.5).toFixed(2) : '...'} kg of food was consumed and not ending up in a landfill.
                </p>

                {/* FAQ Item 3 */}
                <p className="card-title-small">How do you make money?</p>
                <p className="expanded-content">
                    We currently operate as a free service. Our operating costs are ~ $85 a month all in. Please consider making a donation so we don't ever have to run clunky ads.  
                    You can support us <a href="https://buy.stripe.com/00g4hyguj2ND7a8144" target="_blank" rel="noopener noreferrer" className="highlight-email">here</a>.
                </p>

                {/* FAQ Item 2 */}
                <p className="card-title-small">How does it work?</p>
                <p className="expanded-content">
                    Our system combines pattern recognition, language models, and culinary logic to analyze your available ingredients and instantly generate creative, practical recipes tailored to what you have in your kitchen. We match cooking techniques with ingredient combinations to suggest tasty dishes that help you reduce waste. Our system is designed to be simple and easy to use for everyday people... not Michelin-star chefs.
                </p>

                {/* FAQ Item 4 */}
                <p className="card-title-small">Does this work with dietary restrictions?</p>
                <p className="expanded-content">
                    Yes, you can specify vegetarian, vegan, gluten-free, and dairy-free preferences when generating recipes. However, since our system generates recipes in real-time, we recommend double-checking ingredients and substitutions if you have serious dietary restrictions or allergies. While we strive for accuracy, we cannot guarantee ingredient cross-contamination risk levels or absolute compliance with specific dietary restrictions—please use your best judgment and consult medical professionals if you have specific dietary needs.
                </p>

                {/* FAQ Item 5 */}
                <p className="card-title-small">Where do calorie counts come from?</p>
                <p className="expanded-content">
                    Calorie counts are rough approximations generated by our AI system. While we strive for accuracy, Ratatouille is focused on reducing food waste rather than precise nutritional tracking. If you're following a specific diet or need exact calorie counts, please consult with a nutritionist or use dedicated nutrition apps.
                </p>

                {/* FAQ Item 6 */}
                <p className="card-title-small">Is this app unique to New York City?</p>
                <p className="expanded-content">
                    No, while we started in NYC and love our city's unique food culture, our recipe suggestions work anywhere. We just happen to have a soft spot for the big apple. Anyone looking to reduce food waste and make the most of their ingredients can use this platform.
                </p>

                {/* FAQ Item 7 */}
                <p className="card-title-small">Can I contribute my own recipes?</p>
                <p className="expanded-content">
                    We currently do not have a feature for users to contribute their own recipes. However, we are working on it! If you have any other suggestions for the platform, please let us know via the form <a href="https://docs.google.com/forms/d/e/1FAIpQLSfAUxW8E-J93jakl7F9t8Ni4iylDg54DOxA7B03GczpuWPzEg/viewform?usp=header" target="_blank" rel="noopener noreferrer" className="highlight-email">here</a>.
                </p>

            </div>
        </div>
    );
};

export default Faq;