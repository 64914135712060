import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>
        &copy; 2025 Ratatouille NYC. All rights reserved.{' '}
        <span style={{ color: '#5918bb' }}> Beta v0.1</span>
      </p>
    </footer>
  );
}

export default Footer;